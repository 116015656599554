.App {
  text-align: center;
}

.uiViewContainer{
  padding-top: 40px;
}

.loading{
  color: #fff;
  text-align: center;
  padding: 300px;
  font-size: 40px;
}

.refreshError{
  color: rgb(185, 0, 0);
  text-align: center;
  padding: 300px;
  font-size: 20px;
}


.base {
  background-color: #070707;
  min-height: 100vh;
  font-family: "Poppins", sans-serif;
}


.sidebarContainer {
  display: flex;
padding-top: 50px;
flex-direction: row;

}

.sidebar {
  padding-left: 15px;
  width: 20%;
  
}

.sidebarPrimary {
  width: 80%;
  text-align: center;
 
}

.mainView {
  padding: 50px;
  text-align: center;
}

.classContainer {
  margin-top: -30px;
}

.classHeading {
  padding-bottom: 0px;
  font-size: 24px;
}

.classTitles {
  font-size: 18px;
  color: #fff;
  padding-bottom: 0px;
  margin-bottom: 0px;
  line-height: 8px;
  font-weight: 100;
}

.classData {
  font-size: 18px;
  color:#59c2f3;
  padding-bottom: 0px;
  margin-bottom: 0px;
  font-weight: 900;
}



.mini1 {
  background-image: linear-gradient(20deg , #141414, 80%, #195956);
  color: #747474;
  min-height: 150px;
  border-radius: 8px;
  font-weight: 700;
  box-shadow: #000000 0px 0px 20px;
  text-align: left;
}

.mini2 {
  background-image: linear-gradient(20deg , #141414, 80%, #594f19);
  color: #747474;
  min-height: 150px;
  border-radius: 8px;
  font-weight: 700;
  box-shadow: #000000 0px 0px 20px;
  text-align: left;
}

.mini3 {
  background-image: linear-gradient(20deg , #141414, 80%, #3a1959);
  color: #747474;
  min-height: 150px;
  border-radius: 8px;
  font-weight: 700;
  box-shadow: #000000 0px 0px 20px;
  text-align: left;
}

.mini4 {
  background-image: linear-gradient(20deg , #141414, 80%, #59192e);
  color: #747474;
  min-height: 150px;
  border-radius: 8px;
  font-weight: 700;
  box-shadow: #000000 0px 0px 20px;
  text-align: left;
}

.groupStat {
  margin-top: 10px;
  color: #747474;
  background-image: linear-gradient(20deg , #0a0d0f, 60%, #12181b);
  min-height: 150px;
  border-radius: 8px;
  font-weight: 700;
  box-shadow: #000000 0px 0px 20px;
  text-align: center;
}


.contain {
  max-height: 220px;
}



.col4 {
  margin-top: 0px;
  background-color: #141414;
  color: #747474;
  padding-left: 20px;
  min-height: 200px;
  border-radius: 8px;
  font-weight: 700;
  box-shadow: #000000 0px 0px 20px;
  text-align: left;
}

.col6 {
  background-color: #141414;
  color: #747474;
  min-height: 300px;
  border-radius: 8px;
  font-weight: 700;
  box-shadow: #000000 0px 0px 20px;
  text-align: left;
}

.col8 {
  background-image: linear-gradient(20deg , #141414, 80%, #193B59);
  color: #747474;
  padding-left: 20px;
  min-height: 410px;
  border-radius: 8px;
  font-weight: 700;
  box-shadow: #000000 0px 0px 20px;
  text-align: left;
}

.col8Live {
  background-image: linear-gradient(20deg , #141414, 80%, #193B59);
  color: #747474;
  margin-top:28px;
  padding-top: 20px;
  padding-left: 20px;
  min-height: 335px;
  border-radius: 8px;
  font-weight: 700;
  box-shadow: #000000 0px 0px 20px;
  text-align: left;
}

.col12 {
  background-image: linear-gradient(20deg , #141414, 70%, #193B59);
  color: #747474;
  padding-left: 20px;
  padding-right: 20px;
  min-height: 300px;
  max-height: 300px;
  border-radius: 8px;
  font-weight: 700;
  box-shadow: #000000 0px 0px 20px;
  text-align: left;
}

.line {
  height: 2px;
  background-image: linear-gradient(to right, #dc493a, #193B59);
  margin-bottom: 0px;
}

.vl {
  border-left: 3px solid #dc493a;
  height: 100px;
  left: 50%;
  margin-top: 20px;
  margin-left: -3px;
  top: 0;
}

.vlLarge {
  border-left: 3px solid #dc493a;
  height: 165px;
  left: 50%;
  margin-top: 20px;
  margin-left: -3px;
  top: 0;
}

/* .line {
  height: 2px;
  background-color: rgb(190, 89, 6);
  margin-bottom: 0px;
} */

.thinLine {
  height: 0.5px;
  background-image: linear-gradient(to right, #adadad, #222222);
  margin-bottom: 10px;
}


.boldLine {
  height: 2px;
  background-image: linear-gradient(to right, #dc493a, #193B59);
  margin-bottom: 10px;
}

.loginForm {
  background-color: #c2c2c2;
  margin-top: 20px;
  padding: 30px;
  border-radius: 10px;

}

.searchForm {
  background-image: linear-gradient(20deg , #141414, 80%, #193B59);
  margin-top: 20px;
  padding: 30px;
  border-radius: 10px;
  
}

.lookupResults {
  font-kerning: 2em;
  font-weight: 100;
  font-size: 16px;
}

.header {
  height: 50px;
  /*background-color: #02183a;
  box-shadow: rgba(0, 0, 0, 0.39) 0px 5px 10px;*/
  text-align: left;
  color: rgba(244, 244, 244, 0.725);
}

.analyticsRowFirst {
  margin-bottom: 30px;
}

.analyticsRow {
  margin-bottom: 30px;
}

.liveDataRow {
  margin-bottom: 30px;
  margin-top: -30px;
}

.middleContainer {
  margin-top: 30px;
}

.riderAnalyticsRow {
  background-image: linear-gradient(20deg , #141414, 80%, #1d646e);
  padding-bottom: 15px;
  margin-bottom: 30px;
  border-radius: 8px;
}

.teamAnalyticsRow {
  background-image: linear-gradient(20deg , #141414, 70%, #193B59);
  padding-bottom: 15px;
  margin-bottom: 30px;
  border-radius: 8px;
}


.classRow {
  background-image: linear-gradient(20deg , #141414, 70%, #0c1022);
  padding-bottom: 15px;
  margin-bottom: 0px;
  border-radius: 8px;
}

.squareStat {
  border-right: 10px soild red;
}

.classStat {
  border-right: 10px soild red;
}

.blockHeader {
  font-weight: 100;
  font-size: 22px;
  color: beige;
  text-transform: uppercase;
}

.blockHeaderLive {
  font-kerning: 2em;
  font-weight: 900;
  font-size: 26px;
  color: #cecece;
  text-transform: uppercase;
  background-image: linear-gradient(45deg , #141414 85%, #00e4c6 15%);
  margin-top: -20px;
  margin-bottom: 10px;
  border-radius: 8px;
  padding: 0px;
  margin-left: -15px;
  margin-right: -15px;
}

.blockSubheader {
  font-weight: 100;
  font-size: 14px;
  padding-top: 20px;
  margin-bottom: -12px;
  color: #227cf2;
}


.blockSubheaderInner {
  margin-top: 0px;
  font-weight: 100;
  font-size: 14px;
  padding-top: 20px;
  margin-bottom: -12px;
  color: #227cf2;
}

.miniBlockSubheader {
  font-weight: 100;
  font-size: 14px;
  margin-bottom: -20px;
  color: #ffffff;
  text-align: right;
}
.groupBlockSubheader {
  font-weight: 900;
  font-size: 14px;
  margin-bottom: -20px;
  color: #ffffff;
  text-align: center;
}

.teamTotals {
  color: #dc493a;
  font-weight: 700;
  font-size: 16px;
  margin-top: -22px;
}

.teamHeaderLive {
  margin-top: 55px;
  text-align: center;
}

.blockData {
  font-weight: 900;
  font-size: 30px;
  color: beige;
  text-transform: uppercase;
  text-align: right;
}

.groupBlockData {
  font-weight: 900;
  font-size: 40px;
  color: #dc493a;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 0px;
  transform: translate(0%);
  transition-property: transform;
  transition-duration: 1s
}

.detailText {
  font-weight: 100;
  color: #747474;
  font-size: 14px;
  text-transform: uppercase;
  padding-bottom: 8px
}

.groupDetailText {
  font-weight: 100;
  color: #b8b8b8;
  font-size: 16px;
  text-transform: uppercase;
  padding-bottom:50px
}

.miniStatIcon {
  padding-top: 20px;
  max-width: 40px;
  max-height: 40px;
}

#riders {
  color: rgb(124, 124, 124);
  font-size: 12px;
  font-weight: 100;
  border-collapse: collapse;
  width: 100%;
  padding: 5px;
}

#riders td {
  border-bottom: 1px solid rgba(70, 189, 197, 0.144);
  padding-top: 8px;
  padding-bottom: 5px;
}

#riders th {
  color: #276194;
}

.riderName {
  font-weight: 700;
}

.bigStatNum {
  text-align: center;
  font-weight: 900;
  font-size: 40px;
  color: #dc493a;
}

.bigStatNumTeam {
  padding: 27px;
  text-align: center;
  font-weight: 900;
  font-size: 40px;
  color: #dc493a;
}


.bigStatText {
 text-align: center;
 margin-top: -20px;
 font-weight: 100;
 color: #747474;
 font-size: 14px;
}

.megaStat {
  margin-top: -30px;
}

.megaStat2 {
  margin-top: 0px;
}

.liveIndicator {
  color: #289aff;
  animation: bulge 4s ease infinite;
}

@keyframes bulge {
  0% {opacity: 1; color: #1fa17a}
  50% {opacity: 1; color: #19588f}
  100% {opacity: 1; color: #1fa17a}
}

.scroll {
  min-height: 250px;
  max-height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
  
}

::-webkit-scrollbar {
 width: 2px;
}

::-webkit-scrollbar-thumb {
  background: #193B59;
  border-radius: 10px;
}

.teamButton {
  color: beige;
  background-color: #333;
  border: 0px;
  border-radius: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  margin: 5px;
}

.logo {
  max-width: 135px;
  padding: 0px;
  margin-top: 10px;
  opacity: 0.8;
}

.table {
  padding-left:0px;
}

.dropdown {
  background: -webkit-linear-gradient(#222, #222);
  margin-top: 20px;
  padding: 4px;
  background-color: #222;
  border: 0px;
  border-radius: 5px;
  color: beige;
  -webkit-appearance: none
}

.dropdown:focus {
  outline:none;
}

.rightAlign {
  text-align: right;
}

.innerContainer {
  padding-top: 0px;
}

.clock {
  color: #166db9;
  padding-top: 30px;
  font-size: 18px;
  font-weight: 900;
}

.dataAlert {
  margin-top: 10px;
  text-align: right;
  right: 210px;
}

.cred {
  font-size: 12px;
  color: #a7a7a7;
  text-align: left;
  position: fixed;
  bottom: 20px;
  left: 46.5%;
}

.scroller {
  color: #fff;
  opacity: 1;
  animation: fade 2s linear;
}

@keyframes fade {
  0%, 100% {opacity: 0}
  50% {opacity: 1}
}

.miniIcon {
  padding-right: 5px;
  max-height: 15px;
}








@media screen and (max-width: 960px) {
}

@media screen and (max-width: 990px) {
  .miniStatContainer {
    min-width: 50% !important;
    flex-basis: 0 0 50% !important;
    margin-bottom: 30px;
  }
  .blockData {
    font-size: 32px;
  }
  .statRow {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .miniStatContainer {
    max-width: 100% !important;
    flex-basis: 0 0 100% !important;
    height: auto;
    margin-bottom: 0px;
    
  }

  .squareStat {
    max-width: 50% !important;
    flex-basis: 0 0 50% !important;
  }

  .middleContainer {
    margin-top: 20px;
    margin-bottom: -35px;
  }

  .miniBlockSubheader {
    font-size: 18px;
  }
  .blockData {
    font-size: 30px;
  }
  .detailText {
    font-size: 16px;
    padding-bottom: 10px;
  }

  .miniStatLeft {
    max-width: 25% !important;
  }

  #riders {
    font-size: 11px;
  }
  .logo {
    margin-top: 30px
  }
  .teamList {
    margin-top: 10px;
  }

  .riderAnalyticsRow {
    background-image: linear-gradient(20deg , #141414, 80%, #1d646e);
    padding-bottom: 15px;
    margin-bottom: 30px;
    border-radius: 8px;
  }

  .groupBlockData {
    font-weight: 900;
    font-size: 40px;
    color: #dc493a;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 0px;
    transform: translate(0%);
    transition-property: transform;
    transition-duration: 1s
  }

  .classStat {
    margin-bottom: 0px;
  }
  .classRow {
    margin-top: 50px;
  }

  .miniStat {
    justify-content: center !important;
  }

  .loading {
    padding: 0px;
    padding-top: 100px;
    font-size: medium;
  }

  .cred {
    display: none;
  }
 
}
