.siteHeader {
    color: white;
}

.logo {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 70%;
}

.centerAll {
display: flex;
align-items: center;
justify-content: center;
height: 100vh;
}

.siteSubhead {
    color: #11bbad;
    line-height: 4;
    font-weight: 100;
}

.blockContent {
    text-align: center;
}